import React, { useState } from 'react'
import courseImg from '../Images/cat_friends.svg'
// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//sweetalert
import swal from 'sweetalert';

//routers
import {useNavigate } from "react-router-dom";




function AcademicComp() {

    //states
    const [courseName, setCourseName] = useState("");
    const [courseDesc, setCourseDesc] = useState("");
    const [studentStatus, setStudentStatus] = useState(""); 

    const auth = getAuth();
    let navigate = useNavigate();

    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{
            const course = doc.data().course;
            const studentStatus = doc.data().Status;
            viewCoursePrice(course, studentStatus);
            })
        })
        } else {
        // User is signed out
        
        }
    });

    //view the course
   function  viewCoursePrice(course,studentStatus){

        //show course details
        const courseQ = query(collection(db, "courses"), where("mcourseName", "==", course));    
        getDocs(courseQ).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{
            const mcourseName = doc.data().mcourseName;
            const mcourseDescription = doc.data().mcourseDescription;  
            const courseLink = doc.data().courseLink;         
            
            setCourseName(mcourseName);
            setCourseDesc(mcourseDescription);  
            
            //join virtual class
            window.joinVirtualClass = function(){

                if(studentStatus === "Ongoing"){
                    swal("Error", "Your Student status is not yet active. Contact admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })
                }else if(studentStatus === "Active"){ 
                    
                    //navigate("/my-rooms")
                   // window.location.href= courseLink;

                }else if (studentStatus === "Suspended"){

                    swal("Error", "You have a pending Invoice. Kindly Contact the admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })

                }else if (studentStatus === "Completed"){

                }
            }
            //end

            //view course outline
            window.viewCourseOutline = function(){
                if(studentStatus === "Ongoing"){
                    swal("Error", "Your Student status is not yet active. Contact admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })

                }else if(studentStatus === "Active"){

                    

                }else if (studentStatus === "Suspended"){
                    swal("Error", "You have a pending Invoice. Kindly Contact the admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })
                }else if (studentStatus === "Completed"){

                }
            }
            //end


            })

        })




        //status button
        if(studentStatus === "Ongoing"){
            setStudentStatus("Inactive");
        }else if(studentStatus === "Active"){
            setStudentStatus("Ongoing");
        }else if (studentStatus === "Suspended"){
            setStudentStatus("Suspended");
        }else if (studentStatus === "Completed"){
            setStudentStatus("Completed");
        }


    }

  return (
    <div className='academicComp'>

        <h5>My Pathways</h5>

        <div className='courseContainer'>

            <div className='courses'>
                <div className='courseTop'>
                    <img src={courseImg} alt="" />
                    <h5>{courseName}</h5>
                </div>

                <h6>About the Program</h6>
                <p>{courseDesc}</p>

                <div className='d-flex justify-content-center'>
                    <button onClick={window.joinVirtualClass}>Performance Analysis</button>
                    <button onClick={window.viewCourseOutline} className='courseOutlineBtn'>Schemes of Work</button>
                </div>

                <span>{studentStatus}</span>
                
            </div>

        </div>
    </div>
  )
}

export default AcademicComp