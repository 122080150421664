import React from 'react'

function AddStartupComp() {
  return (
    <div className='startupComp'>
        <div className='startupTop'>
            <h5>Add a new startup / Project / SaaS</h5>
        </div>

        <div className='startupContainer'>

            <div className='addStartupCards'>
                <h6>About your Startup</h6>
                <p>How would you prefer to call your startup project?</p>
                <input type="text"  className='form-control' />
                <p>in less than 15 words explain what your startup is</p>
                <textarea name="" id="" className='form-control'   rows="2"></textarea>
                <p>Solution Type</p>
                <select name="" id="" className='form-control'>
                    <option value="">Web Application</option>
                    <option value="">Mobile Application</option>
                    <option value="">Desktop Application</option>
                    <option value="">Embedded Systems</option>
                    <option value="">Web & Mobile App</option>
                    <option value="">Web, Mobile & Desktop App</option>
                    <option value="">Other</option>
                </select>
                <p>If other, explain below</p>
                <input type="text" className='form-control'/>
                <p>Startup's landing page url</p>
                <input type="text" className='form-control' placeholder='eg www.exapmle.com'/>
            </div>

            <div className='addStartupCards'>
                <h6>Problem</h6>
                <p>What is the status quo? what is the problem you ar trying to solve?</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Solution</h6>
                <p>What is your Solution to he problem above? How are you slving the above problem</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Business Model</h6>
                <p>What is your business model? How do you plan to make revenue? Explain a few scalable and sustainable revenue streams.</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Market Potential</h6>
                <p>Who is your target market?</p>               
                <textarea name="" id="" className='form-control'   rows="1"></textarea>
                <p>What is your target market size</p>
                <textarea name="" id="" className='form-control'   rows="1"></textarea>
                <p>Explain your niche market</p>
                <textarea name="" id="" className='form-control'   rows="1"></textarea>
                <p>Some target market consumer/Spending behavior</p>
                <textarea name="" id="" className='form-control'   rows="1"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Competition</h6>
                <p>Do you have potential competition? How different are you from them? Explain your competitors.</p>
                <textarea name="" id="" className='form-control'   rows="4"></textarea>
                <p>Provide links to some of your competitors below (separate with commas)</p>
                <textarea name="" id="" className='form-control'   rows="2"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Founding Team</h6>
                <p>Describe your founding team and your distribtion on tasks</p>
                <textarea name="" id="" className='form-control'   rows="3"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Marketing</h6>
                <p>Marketing is the engine of any organisation. How are you planning to make the revenues above? What is your go-to market strategy?  Be sure to lay out short- and long-term customer acquisition strategies that answer that question. Focus on the metrics of unit economics of how much it costs to acquire a customer and the LTV (Lifetime Value of Customer). These metrics are the heartbeat of any company.</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
            </div>

            <div className='addStartupCards'>
                <h6>Fundraising</h6>
                <p>What is your startup capital? How much money do you need for your startup to start working?</p>
                <input type="text" className='form-control' placeholder='eg. 1,500,000'/>
                <p>Have a breakdown of the above</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
                <p>How much money do you need for day to day operation of your startup for the next 2 years?</p>
                <input type="text" className='form-control' placeholder='eg. 5,000,000'/>
                <p>Have a breakdown of the above</p>
                <textarea name="" id="" className='form-control'   rows="5"></textarea>
                <p>How do you want to fund the above?</p>
                <select name="" id="" className='form-control'>
                    <option value="Bootstrapping">Bootstrapping</option>
                    <option value="Venture Capital">Venture Capital</option>
                    <option value="Angel Invetors">Angel Invetors</option>
                    <option value="Crowdsourcing">Crowdsourcing</option>
                    <option value="Savings">Savings</option>
                    <option value="Debpt Financing">Debpt Financing</option>
                </select>
            </div>

            <div className='addStartupCards'>
                <h6>Breakeven Analysis</h6>
                <p>Explain your breakeven analysis. This is a financial calculation that weighs the costs of a new business, service or product against the unit sell price to determine the point at which you will break even. In other words, it reveals the point at which you will have sold enough units to cover all of your costs.</p>
                <textarea name="" id="" className='form-control'   rows="3"></textarea>
                <p>Use: Break-Even point (units) = Fixed Costs ÷ (Sales price per unit – Variable costs per unit)</p>
            </div>




            <button className='btn btn-primary'>Add Startup</button>

           

        </div>
        
    </div>
  )
}

export default AddStartupComp