import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud} from '@fortawesome/free-solid-svg-icons'
import { faCake} from '@fortawesome/free-solid-svg-icons'
import iconGraduate from '../Images/icon_graduate.svg'
import axios from 'axios'

function ClientsComp() {

  //date functions
  const todaysDate = new Date();
  const todayDay = todaysDate.getDate();
  const todayDayOfWeek = todaysDate.getDay();
  const todayMonth = todaysDate.getMonth();
  const todayHour = todaysDate.getHours();
  const todayMinute = todaysDate.getMinutes();

  const weekArray = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthArray = ["January","February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [todayDayState] = useState(todayDay);
  const [todayWeekState] = useState(weekArray[todayDayOfWeek]);
  const [todayMonthState] = useState(monthArray[todayMonth]);
  const [todayHourState, setTodayHourState] = useState(0);
  const [todayAmPmState, setTodayAmPmState] = useState("");
  const [todayMinuteState] = useState(todayMinute);
  const myHourTime = todayHour - 12;
  //const sweetMinutes = "0" + todayMinute;


  const [myCity, setMyCity] = useState();
  useEffect(()=>{


    axios.get('https://json.geoiplookup.io/')
    .then(res=>{
      const theCity = res.data.city;

      setMyCity(theCity)

    }).catch((err=>{
      console.log(err)
    }))
    
    const myWeatherApi = 'https://api.weatherapi.com/v1/current.json?key=191ec17193ac4ef788a122150221301&q=Nairobi&aqi=yes'

    axios.get(myWeatherApi)
    .then(res=>{

      console.log(res)

    }).catch((err=>{
      console.log(err)
    }))

  },[])


  console.log(myCity)

  useEffect(() => {
    if(todayHour > 12){
      setTodayHourState(myHourTime);
      setTodayAmPmState("PM")
    }else if(todayHour === 12){
      setTodayHourState(todayHour);
      setTodayAmPmState("PM")
    }else{
      setTodayHourState(todayHour);
      setTodayAmPmState("AM")
    }
   
  }, [myHourTime, todayHour]);
  //dates end


  return (
    <div className='clientsComp'>
        <h5>Research & Development - Project list</h5>
        <div className='clientsTop'>
         
          <div className='clientLeft'>
            <div className='clientAlloc'>
              <h6>My Earnings</h6>
              <hr />
              <h4>KES. 0</h4>
              <p>Current balance</p>

              <ul>
                <li>This weeks earnings <span>KES.0</span> </li>
                <li>This Months earnings <span>KES.0</span> </li>
                <li>This Years earnings <span>KES.0</span> </li>
              </ul>

              <div className='clientAllocIconCont'>
                  <img src={iconGraduate} alt="" /> <br />
                  <button>Widthraw</button>
              </div>
            </div>

          </div>

          <div className='clientRight'>

            <div className='cliRight1'>
                <div className='cliRoundIconCont'>
                  <div className='cliRoundIcon1'>
                    <FontAwesomeIcon icon={faCloud} className=''/>
                  </div>
                </div>

                <div className='clirightCont'>
                  <h4>0</h4>
                  <p>Client Lists</p>
                </div>
            </div>

            <div className='cliRight2'>
                <div className='cliRoundIconCont'>
                  <div className='cliRoundIcon'>
                    <FontAwesomeIcon icon={faCloud} className=''/>
                  </div>
                </div>

                <div className='clirightCont'>
                  <h4>0</h4>
                  <p>Allocated Projects</p>
                </div>
            </div>

            <div className='cliRight3'>
              <div>
                <p>{todayWeekState}, {todayDayState} {todayMonthState} </p>
                <h2>{todayHourState}:{todayMinuteState} {todayAmPmState}</h2> 
                <p>{myCity}</p>
              </div>

              <FontAwesomeIcon icon={faCloud} className='cloidIcoClient'/>

              <div>
                <h2>23<sup>o</sup></h2>
                <p>Thunder strom</p>
              </div>

            </div>

          </div>
        </div>

        <div className='allClientsList'></div>
    </div>
  )
}

export default ClientsComp