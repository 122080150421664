import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import EditAccountComp from './EditAccountComp'

function EditAccount() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <EditAccountComp/>
            </div>
        </div> 
    </div>
  )
}

export default EditAccount