import React, { useRef, useState } from 'react'
import logo from '../Images/logo-main-black.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'

//firebase
import {db} from '../firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faLock} from '@fortawesome/free-solid-svg-icons'

function Login() {

    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
    const [therrorMessage, setErrorMessage] = useState("");

    function SignInUser(){
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const auth = getAuth();

        setShowProgress(true);
        setShowAlert(false);
        setShowSignInbtn(false)

        //sign in a user
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            //now lets check who has loggedin 
            //store their user id in the const name below
            let userID = userCredential.user.uid

            const q = query(collection(db, "users"), where("userId", "==", userID));
            getDocs(q).then((QuerySnapshot)=>{
                QuerySnapshot.forEach((doc)=>{

                    let userType = doc.data().userType;

                    if (userType === "Instructor" ){
                        navigate("/in/dashboard")
                    }else if(userType === "student"){
                        navigate("/dashboard")
                    }             
                    
                })
            })

        })
        .catch((error) => {
            //const errorCode = error.code;
            const errorMessage = error.message;
            setShowAlert(true)
            setErrorMessage(errorMessage)    
            setShowProgress(false)
            setShowSignInbtn(true)
        });
    }


  return (
    <div className='d-flex loginContainer'>
        <div className='loginLeft'>

            <video src="https://res.cloudinary.com/ubunifu/video/upload/v1721608926/5536734-uhd_3840_2160_25fps_bonyjy.mp4" muted autoPlay loop></video>

        </div>
        {/* <div className='loginLeft'>
            <div className='loginOpacity'></div>
            <div className='loginCont'>
                <h3>Karibu!</h3>
                <p>"If you can read and write, you can learn how to code!"</p>
                <div className='d-flex'>                  
                    <div className='loginPils'></div>
                    <div className='loginDots'></div>
                    <div className='loginDots'></div>
                    <div className='loginDots'></div>
                </div>
            </div>

        </div> */}
        <div className='loginRight'>
            <Link to="/"><img src={logo} alt="" className='loginLogo' /></Link>   
            <h5>Welcome back!</h5>   
            <h6>Enter your email address and password to access your Account.</h6> 

            <Form.Label className="inputLabel">Email</Form.Label>
            <InputGroup className="mb-3">               
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faEnvelope} className="theLoginIcon"/>
            </InputGroup.Text>
                    <Form.Control
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    type="email"
                    aria-describedby="basic-addon1"
                    className='loginForm'
                    ref={emailRef}
                    />
            </InputGroup> 

            <Form.Label className="inputLabel">Password</Form.Label>
            <InputGroup className="mb-3">               
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faLock} className="theLoginIcon"/>
            </InputGroup.Text>
                    <Form.Control
                    placeholder="Enter your Password"
                    aria-label="Enter your Password"
                    type="password"
                    aria-describedby="basic-addon1"
                    className='loginForm'
                    ref={passwordRef}
                    />
            </InputGroup> 


            {showAlert && 
                <Alert variant={'warning'}  >
                    {therrorMessage}
                </Alert>
            }
            
            <p className="text-muted"><Link to="/recover" className='fgPassLink text-muted'>Forgot Password?</Link></p>
           
            {showSignInbtn && 
                <button  type="submit" onClick={SignInUser} className='signInbtnn'>
                    Sign In
                </button>  
            }

            {showProgress && 
                <Button variant="primary" disabled  className='signInbtnnLoading' >
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span> Logging in...</span>
                </Button>
            }
        </div>     
    </div>
  )
}

export default Login