import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import AssTasksComp from './AssTasksComp'

function AssTasks() {
  return (
    <div>        
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <AssTasksComp/>
                  
            </div>
        </div>  
    </div>
  )
}

export default AssTasks