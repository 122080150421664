import React, { useState, useRef } from 'react'
import coverPhotoContImage from '../Images/default-cover.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';
import Alert from 'react-bootstrap/Alert';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

//routers
import {useNavigate } from "react-router-dom";

function UpdateaccountComp() {
    //states
  const [loggedInUser, setLoggedInUser] = useState("");
  const [userNationality, setNationality] = useState("");

    //states
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userWebsite, setUserWebsite] = useState("");
    const [userAbout, setUserAbout] = useState("");
    const [userExpectation, setUserExpectation] = useState("");
    const [userCourse, setUserCourse] = useState("");
    const [userSessionTime, setUserSessionTime] = useState("");
    const [userClassType, setUserClassType] = useState("");
  
  
    //update user state
    const [updateUserWebsite, setUpdateUserWebsite] = useState("");
    const [updateUserGithub, setUserGithub] = useState("");


    // education
    const [updateuiux,setuiux] = useState("");
    const [updatefrontend,setfrontend] = useState("");
    const [updatebackend,setbackend] = useState("");
    const [updatemobileappandroid,setmobileappandroid] = useState("");
    const [updatemobileappios,setmobileappios] = useState("");
    const [updatedesktopapp,setdesktopapp] = useState("");
    const [updatemydatabases,setmydatabases] = useState("");
    const [updatedatascience,setdatascience] = useState("");
    const [updateelectronics,setelectronics] = useState("");
    const [updateembeddedsystems,setembeddedsystems] = useState("");
    const [updateblockchain,setblockchain] = useState("");

  //get data from input
  const phoneRef = useRef();
  const githubRef = useRef();
  const websiteRef = useRef();
  const aboutMeRef = useRef();


    //get data from input
   const uiux = useRef();
   const frontend = useRef();
   const backend = useRef();
   const mobileappandroid = useRef();
   const mobileappios = useRef();
   const desktopapp = useRef();
   const mydatabases = useRef();
   const datascience = useRef();
   const electronics = useRef();
   const embeddedsystems = useRef();
   const blockchain = useRef();



  const [userProfilePicture, setProfilePicture] = useState("");
  const [userCoverPicture, setCoverPicture] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertTwo, setShowAlertTwo] = useState(false);
  const [UploadBtn, setUploadBtn] = useState("Upload");
  const [uploadProfileStatus, setuploadProfileStatus] = useState("1");
  const [uploadStatStatus, setuploadStatStatus] = useState("Uploading...0%");



  //use ref
  const fileInput = useRef();
  const coverInput = useRef();

  //instantiate storage
  const storage = getStorage();

  //instantiate aut
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
    //if user is logged in 
    const uid = user.uid;

    const q = query(collection(db, "Employees"), where("docId", "==", uid));
    getDocs(q).then((QuerySnapshot)=>{
        QuerySnapshot.forEach((doc)=>{

        const username = doc.data().employeeName;
        const nationality = doc.data().country;
        const profilePic = doc.data().profilePic;
        const coverPhoto = doc.data().coverPhoto;

        const whyInterested = doc.data().whyInterested;
        const aboutMe = doc.data().aboutMe;
        const phoneNumber = doc.data().employeePhone;
        const userEmail = doc.data().userEmail;
        const userWebsite = doc.data().userWebsite;
        const userGithub = doc.data().userGithub;
        const highSchoolMath =  doc.data().highSchoolMath;
        const educationLevel = doc.data().educationLevel;

        const course = doc.data().course;
        const sessionTime = doc.data().sessionTime;
        const classType = doc.data().classType;


        const uiuxValue = doc.data().uiux;
        const frontendValue = doc.data().frontend;
        const backendValue = doc.data().backend;
        const mobileappandroidValue = doc.data().mobileappandroid;
        const mobileappiosValue = doc.data().mobileappios;
        const desktopappValue = doc.data().desktopapp;
    
        const mydatabaseValue = doc.data().mydatabases;
        const datascienceValue = doc.data().datascience;
        const electronicsValue = doc.data().electronics;
        const embeddedsystemsValue = doc.data().embeddedsystems;
        const blockchainValue = doc.data().blockchain;
    
        // update education
        setuiux(uiuxValue);
        setfrontend(frontendValue)
        setbackend(backendValue)
        setmobileappandroid(mobileappandroidValue)
        setmobileappios(mobileappiosValue)
        setdesktopapp(desktopappValue)
        setmydatabases(mydatabaseValue)
        setdatascience(datascienceValue)
        setelectronics(electronicsValue)
        setembeddedsystems(embeddedsystemsValue)
        setblockchain(blockchainValue)

        setUserEmail(userEmail);
        setUserPhone(phoneNumber);
        setUserWebsite(userWebsite);
        setUserAbout(aboutMe);
        setUserExpectation(whyInterested);

        setUserCourse(course);
        setUserSessionTime(sessionTime);
        setUserClassType(classType);

        //update
        setUpdateUserWebsite(userWebsite);
        setUserGithub(userGithub);


        setLoggedInUser(username);
        setNationality(nationality);
        //setProfilePicture(profilePic);
        //setCoverPicture(coverPhoto);


        

        let myxx = profilePic + "1";
        let myYY = coverPhoto + "1";


        if(myxx === "undefined1"){
          setProfilePicture("");         
        }else{
          setProfilePicture(profilePic);
        }

        //
        if(myYY === "undefined1"){
          setCoverPicture(coverPhotoContImage);
        }else{
          setCoverPicture(coverPhoto);
        }

        })
    })

    //update user profile
    window.updateProfile = function(){

    //getting value from ref
    const phoneValue = phoneRef.current.value;
    const githubValue = githubRef.current.value;
    const websiteValue = websiteRef.current.value;
    const aboutMeValue = aboutMeRef.current.value;


    const uiuxValue = uiux.current.value;
    const frontendValue = frontend.current.value;
    const backendValue = backend.current.value;
    const mobileappandroidValue = mobileappandroid.current.value;
    const mobileappiosValue = mobileappios.current.value;
    const desktopappValue = desktopapp.current.value;

    const mydatabaseValue = mydatabases.current.value;
    const datascienceValue = datascience.current.value;
    const electronicsValue = electronics.current.value;
    const embeddedsystemsValue = embeddedsystems.current.value;
    const blockchainValue = blockchain.current.value;


    updateDoc(doc(db, "Employees", user.uid), {
        employeePhone: phoneValue,
        userGithub:githubValue,
        userWebsite: websiteValue,
        aboutMe:aboutMeValue,
        uiux:uiuxValue,
        frontend:frontendValue,
        backend:backendValue,
        mobileappandroid:mobileappandroidValue,
        mobileappios:mobileappiosValue,
        desktopapp:desktopappValue,
        mydatabases:mydatabaseValue,
        datascience:datascienceValue,
        electronics:electronicsValue,
        embeddedsystems:embeddedsystemsValue,
        blockchain:blockchainValue

    }).then(()=>{
        window.location.reload(false);
    })     
    
    }
    //end

    //update profile picture 
    window.saveProfilePhoto = function(){
        const profilePhoto = fileInput.current.files[0];   

        const storageRef = ref(storage, 'images/' + Math.random() + profilePhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, profilePhoto);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                setShowAlert(true);              
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedOffProgress = Math.round(progress);

                setuploadProfileStatus(roundedOffProgress);
                setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                setUploadBtn("Uploading.."+roundedOffProgress +'%')
                
            }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                updateDoc(doc(db, "Employees", user.uid), {
                    profilePic: downloadURL

                }).then(()=>{
                    swal("Success", "Profile picture updated successfully!", "success").then((value) => {
                        window.location.reload(false);
                      });
                    //
                })  
            });
        }
        );
    }
    //end profile picture


    //update Cover photo
    window.saveCoverPhoto = function(){

        const coverPhoto = coverInput.current.files[0];   

        const storageRef = ref(storage, 'images/' + Math.random() + coverPhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, coverPhoto);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                setShowAlertTwo(true);              
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedOffProgress = Math.round(progress);

                setuploadProfileStatus(roundedOffProgress);
                setuploadStatStatus('Upload is ' + roundedOffProgress + '% done');
                setUploadBtn("Uploading.."+roundedOffProgress +'%')
                
            }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                updateDoc(doc(db, "Employees", user.uid), {
                    coverPhoto: downloadURL

                }).then(()=>{
                    swal("Success", "Cover photo updated successfully!", "success").then((value) => {
                        window.location.reload(false);
                      });
                    //
                })  
            });
        }
        );

    }
        } else {
        // User is signed out
        }
    });

    //update profile picture on select
    function updateProfilePhoto(){
        const profilePhoto = fileInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setProfilePicture(url)
    }

    //update profile picture on select
    function updateCoverPhoto(){
        const profilePhoto = coverInput.current.files[0];
        const url = URL.createObjectURL(profilePhoto);
        setCoverPicture(url)
    }

    //go to profile
    let navigate = useNavigate();
    function goToProfilePage(){
        navigate("/profile")
    }



  return (
    <div >
    <div className='profileComp'>
        <div className='profileTop'>
            <div className='coverPhotoCont'>
                <img src={userCoverPicture} alt="" />
            </div>

            <div className='profilePictureCont'>
                <img src={userProfilePicture} alt="" />
            </div>
            <button className='editAccountMobile' onClick={goToProfilePage}><FontAwesomeIcon icon={faArrowLeft} className="profileLocationIcon"/> Back</button>

            <div className='profileInfoCont'>
            <div>
                <h3>{loggedInUser}</h3>
                <p> <FontAwesomeIcon icon={faMapMarkerAlt} className="profileLocationIcon"/> {userNationality}</p>
            </div>      
            
        </div>
        </div> 

        <div className='EditProfileBody'>
            <h6>Update Profile & Cover Photos</h6> 
            <br />

            <p>Profile Picture</p>
            <div className='d-flex'>         
                <input type="file" ref={fileInput} className="form-control" onChange={updateProfilePhoto}/>             
                <button onClick={window.saveProfilePhoto}>{UploadBtn}</button>
            </div>
            <br />

            {showAlert && 
                <Alert>
                    {uploadStatStatus}
                    <ProgressBar striped variant="info" now={uploadProfileStatus} />
                </Alert>
            }

            <p>Cover Photo</p>
            <div className='d-flex'>         
                <input type="file" ref={coverInput} className="form-control" onChange={updateCoverPhoto}/>             
                <button onClick={window.saveCoverPhoto}>{UploadBtn}</button>
            </div>
            <br />

            {showAlertTwo && 
                <Alert>
                    {uploadStatStatus}
                    <ProgressBar striped variant="info" now={uploadProfileStatus} />
                </Alert>
            }



        </div>


        <div className='EditProfileBody2'>
            <h6>Account Details</h6>
            
            <div>
                <label htmlFor="">About Me</label>
                <textarea className='form-control mb-3' rows="3" ref={aboutMeRef} defaultValue={userAbout} ></textarea>

                
                <label htmlFor="">Phone number</label>
                <input type="text" className='form-control mb-3'ref={phoneRef} defaultValue={userPhone} placeholder='eg +254'/>

                <label htmlFor="">Github Account Link</label>
                <input type="text" className='form-control mb-3' ref={githubRef} defaultValue={updateUserGithub} placeholder='https://github.com/user-name'/>
                


                <label htmlFor="">Your Portfolio website</label>
                <input type="text" className='form-control mb-3' ref={websiteRef} defaultValue={updateUserWebsite}  placeholder='www.example.me'/>


               
                

              </div>


              <Button onClick={window.updateProfile}>Update Info</Button>


        </div>

        <div className='EditProfileBody2'>
            <h6>Skills</h6> 
            <p>If skill doesnt apply, leave blank</p>
            <br />


 

            <label htmlFor="">UI/UX</label>
            <input type="text" className='form-control mb-3' ref={uiux} defaultValue={updateuiux}  placeholder='eg Figma'/>

            <label htmlFor="">Front-End Web Development</label>
            <input type="text" className='form-control mb-3' ref={frontend} defaultValue={updatefrontend}  placeholder='eg html,css etc'/>

            <label htmlFor="">Backend-End Web Development</label>
            <input type="text" className='form-control mb-3' ref={backend} defaultValue={updatebackend}  placeholder='eg Node, python etc'/>
           
            <label htmlFor="">Mobile App Development - Android</label>
            <input type="text" className='form-control mb-3' ref={mobileappandroid} defaultValue={updatemobileappandroid}  placeholder='eg Java, etc'/>

            <label htmlFor="">Mobile App Development - iOS</label>
            <input type="text" className='form-control mb-3' ref={mobileappios} defaultValue={updatemobileappios}  placeholder='eg Swift, React Native etc'/>
           
            <label htmlFor="">Desktop App Development</label>
            <input type="text" className='form-control mb-3' ref={desktopapp} defaultValue={updatedesktopapp}  placeholder='eg Java, electron Js etc'/>

            <label htmlFor="">Databases</label>
            <input type="text" className='form-control mb-3' ref={mydatabases} defaultValue={updatemydatabases}  placeholder='eg mySQL, Firebase'/>
           
            <label htmlFor="">Data Science</label>
            <input type="text" className='form-control mb-3' ref={datascience} defaultValue={updatedatascience}  placeholder='eg Python, tableau, etc'/>

            <label htmlFor="">Electronics</label>
            <input type="text" className='form-control mb-3' ref={electronics} defaultValue={updateelectronics}  placeholder='brief desription'/>
            
            <label htmlFor="">Embeded Systems</label>
            <input type="text" className='form-control mb-3' ref={embeddedsystems} defaultValue={updateembeddedsystems}  placeholder='eg Arduino etc'/>

            <label htmlFor="">Blockchain</label>
            <input type="text" className='form-control mb-3' ref={blockchain} defaultValue={updateblockchain}  placeholder='eg solidity etc'/>
            
            <br />

            <Button onClick={window.updateProfile}>Update Info</Button>
            
        </div>
    </div>
   
</div>
  )
}

export default UpdateaccountComp