import React from 'react'
import LeftmenuIn from '../Components/LeftmenuIn'
import DashnavIn from '../Components/DashnavIn'
import StudentsComp from './StudentsComp'

function Students() {
  return (
    <div className='mainContainer'>
        <LeftmenuIn/>
        <div className='theMainContainer'>
        <DashnavIn/>
        <StudentsComp/>
        
        </div>
    </div> 
  )
}

export default Students