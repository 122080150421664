import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import AIComp from './AIComp'

function AiAssistant() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <AIComp/>
            </div>
        </div> 
    </div>
  )
}

export default AiAssistant