import React, { useState, useRef }from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faPhone} from '@fortawesome/free-solid-svg-icons'
import { faGlobe} from '@fortawesome/free-solid-svg-icons'
//import githubIcon from '../Images/gitgub.svg'
import coverPhotoContImage from '../Images/default-cover.gif'
import profilePictureContImage from '../Images/defprofile.png'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";

//routers
import {useNavigate } from "react-router-dom";


function ProfileComp(props) {


  //states
  const [loggedInUser, setLoggedInUser] = useState("");
  const [userNationality, setNationality] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userWebsite, setUserWebsite] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [userExpectation, setUserExpectation] = useState("");
  const [userCourse, setUserCourse] = useState("");
  const [userSessionTime, setUserSessionTime] = useState("");
  const [userClassType, setUserClassType] = useState("");


  //update user state
  const [updateHighSchoolMath, setHighSchoolMath] = useState("");
  const [updateEducationLevel,setEducationLevel] = useState("");

  //profile pic & cover
  const [userProfilePicture, setProfilePicture] = useState(profilePictureContImage);
  const [userCoverPicture, setCoverPicture] = useState(coverPhotoContImage);

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
      if (user) {
      //if user is logged in 
      const uid = user.uid;

      const q = query(collection(db, "users"), where("userId", "==", uid));
      getDocs(q).then((QuerySnapshot)=>{
          QuerySnapshot.forEach((doc)=>{
          const username = doc.data().bio;
          const nationality = doc.data().nationality;
          const whyInterested = doc.data().whyInterested;
          const aboutMe = doc.data().aboutMe;
          const phoneNumber = doc.data().phoneNumber;
          const userEmail = doc.data().userEmail;
          const userWebsite = doc.data().userWebsite;
          const highSchoolMath =  doc.data().highSchoolMath;
          const educationLevel = doc.data().educationLevel;

          const course = doc.data().course;
          const sessionTime = doc.data().sessionTime;
          const classType = doc.data().classType;

          const profilePic = doc.data().profilePic;
          const coverPhoto = doc.data().coverPhoto;       

          setLoggedInUser(username);
          setNationality(nationality);
          setUserEmail(userEmail);
          setUserPhone(phoneNumber);
          setUserWebsite(userWebsite);
          setUserAbout(aboutMe);
          setUserExpectation(whyInterested);

          setUserCourse(course);
          setUserSessionTime(sessionTime);
          setUserClassType(classType);

          //update
          setHighSchoolMath(highSchoolMath);
          setEducationLevel(educationLevel);

          //pictures

          let myxx = profilePic + "1";
          let myYY = coverPhoto + "1";

  
          if(myxx === "undefined1"){
            setProfilePicture(profilePictureContImage);         
          }else{
            setProfilePicture(profilePic);
          }

          //
          if(myYY === "undefined1"){
            setCoverPicture(coverPhotoContImage);
          }else{
            setCoverPicture(coverPhoto);
          }
  
          })
      })

      } else {
      // User is signed out
      }
  });


  //go to edit profile page
  let navigate = useNavigate();
  function goToEdtAccount(){
    navigate("/edit-profile")
  }


  return (
    <div>

        <div className='profileComp'>
            <div className='profileTop'>

                <div className='coverPhotoCont'>
                  <img src={userCoverPicture} alt="" />
                </div>

                <div className='profilePictureCont'>
                  <img src={userProfilePicture} alt="" />
                </div>

                <button onClick={goToEdtAccount} className="editAccountMobile">Edit Account</button>

                <div className='profileInfoCont'>
                  <div>
                    <h3>{loggedInUser}</h3>
                    <p> <FontAwesomeIcon icon={faMapMarkerAlt} className="profileLocationIcon"/> {userNationality}</p>
                  </div>      
                  <button onClick={goToEdtAccount}>Edit Account</button>
                </div>
            
            </div>   

            <div className='profileBody'>
              <div className='profieAboutBody'>
                <div className='profieAboutBodyTop'>
                  <h6><FontAwesomeIcon icon={faInfoCircle} className=""/> About</h6>
                </div>

                <p>{userAbout}</p>

                <hr />
                <h6 className='clTitAbBody'>Contact Info</h6>

                <div className='profileContactCard'>
                  <FontAwesomeIcon icon={faEnvelope} className=""/>
                  <div >
                    <p className='profileContactCardTitle'>Email</p>
                    <p>{userEmail}</p>
                  </div>
                </div>

                <div className='profileContactCard'>
                  <FontAwesomeIcon icon={faPhone} className=""/>
                  <div >
                    <p className='profileContactCardTitle'>Phone</p>
                    <p>{userPhone}</p>
                  </div>
                </div>

                <div className='profileContactCard'>
                  <FontAwesomeIcon icon={faGlobe} className=""/>
                  <div >
                    <p className='profileContactCardTitle'>Your Website</p>
                    <p>{userWebsite}</p>
                  </div>
                </div>

              </div>


              <div className='profileOverview'>
              <div className='overviewProfie'>
                    <h6>My expectations</h6>
                    <p>{userExpectation}</p>
                    

                    <div className='profileOverviewCard'>
                      <div className='roundProfileIconCont'>
                        <FontAwesomeIcon icon={faGlobe} className="profileOverviewIcon"/>
                      </div>
                      <div>
                        <p className='profileContactCardTitle'>Course</p>
                        <p>{userCourse}</p>
                      </div>
              
                    </div>

                    <div className='profileOverviewCard'>
                      <div className='roundProfileIconCont'>
                        <FontAwesomeIcon icon={faGlobe} className="profileOverviewIcon"/>
                      </div>
                      <div>
                        <p className='profileContactCardTitle'>Class Session</p>
                        <p>{userSessionTime}</p>
                      </div>
              
                    </div>

                    <div className='profileOverviewCard'>
                      <div className='roundProfileIconCont'>
                        <FontAwesomeIcon icon={faGlobe} className="profileOverviewIcon"/>
                      </div>
                      <div>
                        <p className='profileContactCardTitle'>Class Type</p>
                        <p>{userClassType}</p>
                      </div>
              
                    </div>

                    <div className='profileOverviewCard'>
                      <div className='roundProfileIconCont'>
                        <FontAwesomeIcon icon={faGlobe} className="profileOverviewIcon"/>
                      </div>
                      <div>
                        <p className='profileContactCardTitle'>Level of Education</p>
                        <p>{updateEducationLevel}</p>
                      </div>
              
                    </div>

                    <div className='profileOverviewCard'>
                      <div className='roundProfileIconCont'>
                        <FontAwesomeIcon icon={faGlobe} className="profileOverviewIcon"/>
                      </div>
                      <div>
                        <p className='profileContactCardTitle'>High School Math Grade</p>
                        <p>{updateHighSchoolMath}</p>
                      </div>
              
                    </div>

                    <div className='theClearJob'>

                    </div>

                    

                  </div>
              </div>
            
            </div>        
        </div>
    </div>
  )
}

export default ProfileComp