import React, { useRef, useState }  from 'react'
import logo from '../Images/logo-main-black.png'
import {NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faCoffee} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { faUsd} from '@fortawesome/free-solid-svg-icons'
import { faMagic} from '@fortawesome/free-solid-svg-icons'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faAnchor} from '@fortawesome/free-solid-svg-icons'
import { faBicycle } from '@fortawesome/free-solid-svg-icons';

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

//routers
import {useNavigate } from "react-router-dom";

function Leftmenu() {

    let navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {

        if (user) {

        } else {
        // User is signed out
        navigate("/login")
        }
    })

  return (
    <div className='leftNav'>
   
        <img src={logo} alt="" className='dashnavLogo' />
      
      
        <br />
        <p>HOME</p>
        

        <NavLink to="/dashboard" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faHome} className="laftNavIcon activelaftNavIcon"/>
            Dashboard
        </NavLink>

        <p>ACADEMIC</p>

        <NavLink to="/academic" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBicycle} className="laftNavIcon"/>
            My Pathways
        </NavLink>

        <NavLink to="/my-rooms" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faVideoCamera} className="laftNavIcon"/>
            My Classes
        </NavLink>


        <NavLink to="/teams" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUsers} className="laftNavIcon"/>
            Teams
        </NavLink>



        <NavLink to="/tasks" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBookmark} className="laftNavIcon"/>
            Assigned Tasks   
            <span className='leftLinkNotif'>0</span>   
        </NavLink>
         


        <NavLink to="/startups" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBriefcase} className="laftNavIcon"/>
            My Startups
        </NavLink>

        {/* className={`leftLinkCont d-flex align-items-center ${activeMenu3}`} */}

        <p>RESEARCH & DEV</p>

        <NavLink to="/assigned-profects" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCoffee} className="laftNavIcon"/>
            Client's Projects
        </NavLink>


        <p>UPDATES & HELP</p>


        <NavLink to="/class-reports" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCalendarDay} className="laftNavIcon "/>
            Class Report
        </NavLink>


        <NavLink to="/fee-report" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUsd} className="laftNavIcon "/>
            Fee Report
        </NavLink>

        <NavLink to="/ai-assistant" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
          <FontAwesomeIcon icon={faMagic} className="laftNavIcon "/>
          AI Assistant
        </NavLink>
        
        <p>Account</p>
        <NavLink to="/profile" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUser} className="laftNavIcon "/>
            Profile
        </NavLink>


      

    </div>
  )
}

export default Leftmenu