import React, { useState, useRef }from 'react'
import DailyIframe from '@daily-co/daily-js';
// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//routers
import { useNavigate, useLocation } from 'react-router-dom';

function ClassCalls() {


    const [loggedInUser, setLoggedInUser] = useState("");

    const auth = getAuth();
    let navigate = useNavigate();

    // receive data from the previous page
    const location = useLocation();
    const myData = location.state?.myData;

    console.log(myData)

    const roomlink = "https://ubunifu.daily.co/" + myData


    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{

                const username = doc.data().bio;
                setLoggedInUser(username);
            })
        })
        } else {
        // User is signed out
        navigate("/login")
        }
    });


    // starting the call
        
    let callFrame = DailyIframe.createFrame({
        showLeaveButton: true,
        iframeStyle: {
        position: 'fixed',
        top: '0',
        right: '0',
        width: '100%',
        height: '100%',
        
        },
    });
    callFrame.join({ url: roomlink });
    callFrame.setUserName(loggedInUser);
    


    callFrame.on('left-meeting', () => {
        window.location.href = 'https://live.ubunifucollege.com/dashboard'; 
        //navigate("/in/dashboard")
    });
  return (
    <div className='onSessionCont' >

        <p>Loading....</p>

    </div>
  )
}

export default ClassCalls