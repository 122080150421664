import React from 'react'

function DarasaComp() {
  return (
    <div>
        <div className='darasaComp'>
            <h5>Class Reports</h5>             
        </div>

    </div>
  )
}

export default DarasaComp