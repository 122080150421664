import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import MyActiveRomms from './MyActiveRomms'

function MyRooms() {
  return (
    <div>
    <div className='mainContainer'>
       <Leftmenu/>
       <div className='theMainContainer'>
         <Dashnav/>
         <MyActiveRomms/>

       </div>
   </div>  
</div>
  )
}

export default MyRooms