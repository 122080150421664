import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import AddStartupComp from './AddStartupComp'

function AddStartup() {

  return (
    <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <AddStartupComp/>
            </div>
    </div>  
  )
}

export default AddStartup