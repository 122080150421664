import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';



function AIComp() {

  const userInput = useRef();
  const [output, setOutput] = useState('');

  function submitQuery(){
    const theUserInput = userInput.current.value;
    fetch(`https://api.openai.com/v1/engines/davinci/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer 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`
      },
      body: JSON.stringify({
        prompt: theUserInput,
        
        max_tokens: 100,
        n: 1,
        stop: '',
        temperature: 0.5,
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.choices && data.choices.length > 0) {
          setOutput(data.choices[0].text);
        } else {
          setOutput('No response received.');
        }
      })
      .catch(error => {
        console.error(error);
        setOutput('An error occurred. Please try again later.');
      });
  }
  
  return (
    <div>
        <div className='AiComp'>

          <div>

              <p>{output}</p>

          </div>

          <div className='aiChatCont'>
              <input type="text" className='aiChatInput' ref={userInput} placeholder='eg. explain for me this code'/>
              <button onClick={submitQuery} className='aiChatBtn' >Submit</button>
          </div>

          <div className='aiFooterText'>
            {/* <p>Use this code to</p> */}
          </div>

          
        </div>
    </div>
  )
}

export default AIComp