import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import AcademicComp from './AcademicComp'

function Academic() {
  return (
    <div>
         <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <AcademicComp/>

            </div>
        </div>  
    </div>
  )
}

export default Academic