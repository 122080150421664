import React from 'react'

function ClientsComp() {
  return (
    <div className='instructorComponent'>
        ClientsComp
    </div>
  )
}

export default ClientsComp