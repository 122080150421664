import React from 'react'

function ThoughtsComp() {
  return (
    <div className='instructorComponent'>
        ThoughtsComp
    </div>
  )
}

export default ThoughtsComp