import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import ProfileComp from './ProfileComp'

function MyProfile() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <ProfileComp/>
            </div>
        </div> 
    </div>
  )
}

export default MyProfile