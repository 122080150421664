import React, { useState } from 'react'
import logo from '../Images/logo-main-white.png'
import facebookIcon from '../Images/facebook.svg'
import instagramIcon from '../Images/instagram.svg'
import twitterIcon from '../Images/twitter.svg'
import linkedInIcon from '../Images/linkedin.svg'

function Footer() {

    const currentYear = new Date().getFullYear();
    const [theCurrentYear] = useState(currentYear);



  return (
    <div >
          <div className='theFooter'>

            <div className='footer1'>
                <img src={logo} className='footerLogo' alt="" />
                <p>We are a Premium school of Software Engineering, Artificial Intelligence, Data Science, Robotics & Blockchain.</p>
                <div>
                    <img src={instagramIcon} alt="" className='footerIcons'/>
                    <img src={twitterIcon} alt="" className='footerIcons'/>
                    <img src={facebookIcon} alt="" className='footerIcons'/>
                    <img src={linkedInIcon} alt="" className='footerIcons'/>
                </div>
            </div>
            <div className='footer2'>
                <h6>INFORMATION</h6>
                <ul>
                    <li>Students' Portal</li>
                    <li>Virtual Learning</li>
                    <li>Ubunifu Kids</li>
                    <li>Career</li>
                    <li>Referral Program</li>
                </ul>
                
            </div>
            <div className='footer3'>
                <h6>CONTACT INFO</h6>
                <p>Ubunifu College <br />
                Karen & CBD - Nairobi</p>

                <p>Email: admissions@ubunifucollege.com <br />
                Phone: (+254)727 634 101 <br />
                <a href='https://ubunifucollege.com/contact'>VIEW DIRECTION</a></p>
                
            </div>
            <div className='footer4'>
                <h6>INSTAGRAM PORTFOLIO</h6>

                
            </div>


          </div>

          <div className='copySec'>
            <p>© {theCurrentYear}  Ubunifu Group. All rights reserved.</p>

          </div>
    </div>
  )
}

export default Footer