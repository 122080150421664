import React from 'react'
import logo from '../Images/logo-main-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {useNavigate } from "react-router-dom";


function NavBar() {

  let navigate = useNavigate();

  function goToLogin(){
      navigate("/login");
  }

  return (
    <div className='navBar'>
        <a href="https://ubunifucollege.com/"><img src={logo} alt="logo" className='logo' /></a>       
        <ul className='menu'>

            <div className="dropdown listItems">
              <span className=''>ABOUT</span>
              <div className='dropDownComponent'>
                <div className="dropdown-content">
                  <a href="https://ubunifucollege.com/about">Our Story & How we work</a>
                  <a href="https://ubunifucollege.com/methodology">Methodology & Success</a>
                  <a href="https://ubunifukids.com/">Ubunifu Kids</a>
                  <a href="https://lab.ubunifucollege.com/">Centre for AI Research & Development</a>
                  <a href="https://ubunifucollege.com/career">Career</a>
                  <a href="https://ubunifucollege.com/referral">Referral Program</a>
                </div>
              </div>
            </div>

            <div className="dropdown listItems">
              <span className=''>PATHWAYS</span>
              <div className='dropDownComponent'>
                <div className="dropdown-content">
                  <a href="https://ubunifucollege.com/software-development">Software Development</a>
                  <a href="https://ubunifucollege.com/machine-learning">Machine Learning (AI)</a>
                  <a href="https://ubunifucollege.com/data-science">Data Science</a>
                  <a href="https://ubunifucollege.com/pathways/blockchain/">Blockchain</a>
                  <a href="https://ubunifucollege.com/pathways/robotics/">Robotics</a>
                </div>
              </div>

            </div>

            <li className='listItems'><a href='https://live.ubunifucollege.com/'>VIRTUAL LEARNING</a></li>
            <li className='listItems'><a href='https://ubunifucollege.com/updates'>UPDATES</a></li>
            <li className='listItems'><a href='https://ubunifucollege.com/contact'>CONTACT</a></li>
        </ul>
        <p><FontAwesomeIcon icon={faBars} className="theBarsIcon" /></p>
        <button className='signIn' onClick={goToLogin}>Login <FontAwesomeIcon icon={faUser} className="theSign" /></button>
        
    </div>
  )
}

export default NavBar