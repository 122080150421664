import React , { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import defaultAvatar from '../Images/avatar1.png'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//sweetalert
import swal from 'sweetalert';



//routers
import {useNavigate } from "react-router-dom";

function MyActiveRomms() {


    const auth = getAuth();
    let navigate = useNavigate();
    const [studentsList, setStudentList] = useState([]);
  
    
    //fetch all Active Students
    useEffect(() => {
  
      //check if the user is a student
       onAuthStateChanged(auth, (user) => {
         if (user) {
         //if user is logged in 
           const uid = user.uid;


           const i = query(collection(db, "users"), where("userId", "==", uid));
            getDocs(i).then((QuerySnapshot)=>{
                QuerySnapshot.forEach((doc)=>{
                    const studentStatus = doc.data().Status;

                    if(studentStatus === "Ongoing"){
                      swal("Error", "Your Student status is not yet active. Contact admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })
                    }else if(studentStatus === "Active"){ 
                        
                      // window.location.href= courseLink;
      
                    }else if (studentStatus === "Suspended"){
      
                      swal("Error", "You have a pending Invoice. Kindly Contact the admin", "warning").then((result) => {
                        navigate("/dashboard")
                      })
      
                    }else if (studentStatus === "Completed"){
      
                    }
                    

                })
            })

           
    
               
          //this is the magic
          const fetchData = async () => {

            const q = query(collection(db, "classSessions"));
            let studentsItem = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {

              let theClassId =  doc.data().theDocId
              let instructor = doc.data().instructor;
              
              //console.log(theClassId)    

              const m = query(collection(db, "StudentsInClass"), where("studentId", "==", uid));
              getDocs(m).then((QuerySnapshot)=>{
              QuerySnapshot.forEach((thedoc)=>{

                let classID  = thedoc.data().classID;


                // 
                const x = query(collection(db, "Employees"), where("docId", "==", instructor));
                getDocs(x).then((QuerySnapshot)=>{
                QuerySnapshot.forEach((ourDoc)=>{

                  let instructorId = ourDoc.data().docId;
                  let employeeName = ourDoc.data().employeeName;
                  let profilePic = ourDoc.data().profilePic;

                  let userProfPic = profilePic

                  if (profilePic == undefined){
                    userProfPic = defaultAvatar
                  }else{
                    userProfPic = profilePic
                  }

                 // console.log(instructorId)

                  if (theClassId == classID){
                    //console.log("hello")
                    studentsItem.push({ id: doc.id, ...doc.data(), employeeName, userProfPic });
                    setStudentList([...studentsItem]);   
                  }    

                  })
                })
                // 
                
                //console.log(classID)

                

            })
          })

            });


          };
  
          fetchData();
          //end magic
               
       
         } else {
           // User is signed out
           }
       });
       //end
     
  
   }, []);


    function startChat(roomid){
        console.log(roomid)
    
        navigate('/class-session', { state: { myData: roomid } });
    
     }

  

     



  return (
    <div className='academicComp'>

    <h5>My Class Rooms</h5>


    <div className='courseContainer'>


    {studentsList.map((mystudent) => (
        <div className='teamsItem2' key={Math.random()}>
            <div className='teamsItemProfilecont2'>

              <div className='roomTopdesc'>
                  <div className='defaultAvatarCont'>
                      <img src={mystudent.userProfPic} className='defaultAvatar' alt="" />
                  </div>
                  <div className='roomTopContent'>
                     <h6>{mystudent.classDesc}</h6>
                     <p><FontAwesomeIcon icon={faVideoCamera} />   {mystudent.employeeName}</p>
                  </div>
              </div>


              <div className='roomTimeClasses'> 

                <div>
                  <p className='roomTimeClassesH'>Cohort Date</p>
                  <p className='roomTimeClassesP'>{mystudent.intakeDate}</p>
                </div>

                <div>
                  <p className='roomTimeClassesH'>Start Time</p>
                  <p className='roomTimeClassesP'>{mystudent.startTime}</p>
                </div>

                <div>
                  <p className='roomTimeClassesH2'>End Time</p>
                  <p className='roomTimeClassesP2'>{mystudent.endTime}</p>
                </div>
              </div>

              <div>
                <button onClick={() => startChat(mystudent.roomID)} className='joinMyClass'>Join Class</button>
              </div>

            </div>

        </div>
    ))}



    </div>
</div>
  )
}

export default MyActiveRomms