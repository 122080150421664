import React , { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import linkedInProfileIco from '../Images/linkedinProfile.svg'
import githubProfileIco from '../Images/gitgubProfile.svg'
import twitterProfileIco from '../Images/twitterProfile.svg'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//sweetalert
import swal from 'sweetalert';

//routers
import {useNavigate } from "react-router-dom";

import coverPhotoContImage from '../Images/default-cover.gif'
import profilePictureContImage from '../Images/defprofile.png'


function StudentsComp() {

  const auth = getAuth();
  let navigate = useNavigate();
  const [studentsList, setStudentList] = useState([]);

  //profile pic & cover
  const [userProfilePicture, setProfilePicture] = useState(profilePictureContImage);
  const [userCoverPicture, setCoverPicture] = useState(coverPhotoContImage);

  //start video call
  function startAgroupCall(){
    const roomid = "generalgroup";
    navigate('/class-session', { state: { myData: roomid } });
  }


  //fetch all Active Students
  useEffect(() => {

     //check if the user is a student
      onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
          const uid = user.uid;

          //get user
          const q = query(collection(db, "users"), where("userId", "==", uid));
          getDocs(q).then((QuerySnapshot)=>{

              QuerySnapshot.forEach((doc)=>{

              
                //this is the magic
                const fetchData = async () => {
                  const q = query(collection(db, "users"), where("userStatus", "==", "Student"));
                  let studentsItem = [];
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    studentsItem.push({ id: doc.id, ...doc.data() });
                    setStudentList([...studentsItem]);   
                  });
                };

                fetchData();
                //end magic
              
              })
          })


        } else {
          // User is signed out
          }
      });
      //end
    

  }, []);
  //end fetching active students



  function startChat(){
    swal("Error", "User hasn't enabled this feature", "warning");
  }

  function startStudVidCall(){
    swal("Error", "User hasn't enabled this feature", "warning");
  }


  return (
    <div className='teamsComponent'>
        <div className='teamsTopBar'>
            <h5 className='tHide1'>All Students</h5>
            <h5 className='tHide2'>Students</h5>
            <button className='startOnlSessBtn' onClick={startAgroupCall}> <FontAwesomeIcon icon={faVideoCamera} className="liveSessionIcon"/>Join a general Group session</button>
            <button className='startOnlSessBtn2' onClick={startAgroupCall}> <FontAwesomeIcon icon={faVideoCamera} className="liveSessionIcon"/> Start or Join</button>
        </div>

        <div className='teamsContainer'>

          {studentsList.map((mystudent) => (
            <div className='teamsItem' key={Math.random()}>

                <div className='teamsItemProfilecont'>
                  <div className='teamsUserCont'>
                    <img src={mystudent.profilePic} alt="" />
                  </div>
                  <h6>{mystudent.bio}</h6>
                  <span> <FontAwesomeIcon icon={faMapMarkerAlt} className="allStudentsLocationIcon"/> {mystudent.nationality}</span>
                  <div className='teamsItemsProfileico'>
                    <a href="https://github.com"><img src={githubProfileIco} alt="" /></a>
                    <a href="https://github.com"><img src={linkedInProfileIco} alt="" /></a>
                    <a href="https://github.com"><img src={twitterProfileIco} alt="" /></a>                    
                  </div>
                </div>
                <div className='teamsItemBottomCont'>
                  <div className='teamsItemChatbtn' onClick={startChat}>
                    <p>Chat</p>
                  </div>

                  <div className='teamsItemChatbtn1' onClick={startStudVidCall}> 
                    <p>Video Call</p>
                  </div>
                </div>
            </div>
            ))}


        <div className='clearFixAll'></div>
           



        </div>


        <br /> <br />

    </div>
  )
}

export default StudentsComp