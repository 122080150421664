import React , { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud} from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

function FeeComp() {

  // chartjs
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

//charjs end

  //states
  const [coursePrice, setCoursePrice] = useState("");
  const [courseFeeInstallments, setCourseFeeInstallments] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [amountPaidThisMonth, setAmountPaidThisMonth] = useState("");
  const [balanceThisMonth, setBalanceThisMonth] = useState("");
  const [studentsList, setStudentList] = useState([]);

  //graph data
  const [janGraData, setJanGraData] = useState("");
  const [febGraData, setFebGraData] = useState("");
  const [marchGraData, setMarchGraData] = useState("");
  const [aprilGraData, setAprilGraData] = useState("");
  const [mayGraData, setMayGraData] = useState("");
  const [juneGraData, setJuneGraData] = useState("");
  const [julyGraData, setJulyGraData] = useState("");
  const [augGraData, setAugGraData] = useState("");
  const [sepGraData, setSepGraData] = useState("");
  const [octGraData, setOctGraData] = useState("");
  const [novGraData, setNovGraData] = useState("");
  const [decGraData, setDecGraData] = useState("");


  const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{
            const course = doc.data().course;
            viewCoursePrice(course);
            })
        })
        //end

        //calculate amount paid
        const courseQ = query(collection(db, "Income"), where("studentId", "==", uid));  
        getDocs(courseQ).then((QuerySnapshot)=>{
            let income = 0;
            let thisMonthsIncome = 0;

            let janData = 0;
            let febData = 0;
            let marchData = 0;
            let aprilData = 0;
            let mayData = 0;
            let juneData = 0;
            let julyData = 0;
            let augustData = 0;
            let septemberData = 0;
            let octData = 0;
            let novData = 0;
            let decData = 0;

            QuerySnapshot.forEach((doc)=>{
              const incomeAmount = doc.data().incomeAmount;
              const paymentDate = doc.data().paymentDate;
              const theIncome = parseInt(incomeAmount);

              //calculating total income
              income = theIncome + income;

              //get the current month
              const todaysDate = new Date();
              const thisYear = todaysDate.getFullYear();
              const thisMonth = todaysDate.getMonth();
              const thisMonthYear = thisMonth + " " + thisYear;

              //get when amount was paid
              const datePaid = paymentDate.toDate().toUTCString();
              const date = new Date(datePaid);
              const monthPaid = date.getMonth();
              const yearPaid = date.getFullYear();              
              const paidThisMonthYear = monthPaid + " " + yearPaid;

              //compare prices will be paid this year
              if(thisMonthYear === paidThisMonthYear){
                 thisMonthsIncome = theIncome + thisMonthsIncome;
              }

              //get jan Data
              const janDataq = 0 + " " + thisYear;
              if(janDataq === paidThisMonthYear){
                janData = theIncome + janData;
              }

              //get feb Data
              const febDataq = 1 + " " + thisYear;
              if(febDataq === paidThisMonthYear){
                febData = theIncome + febData;
              }

              //get march Data
              const marDataq = 2 + " " + thisYear;
              if(marDataq === paidThisMonthYear){
                marchData = theIncome + marchData;
              }

              //get april Data
              const aprilDataq = 3 + " " + thisYear;
              if(aprilDataq === paidThisMonthYear){
                aprilData = theIncome + aprilData;
              }

              //get may Data
              const mayDataq = 4 + " " + thisYear;
              if(mayDataq === paidThisMonthYear){
                mayData = theIncome + mayData;
              }

              //get june Data
              const juneDataq = 5 + " " + thisYear;
              if(juneDataq === paidThisMonthYear){
                juneData = theIncome + juneData;
              }

              //get july Data
              const julyDataq = 6 + " " + thisYear;
              if(julyDataq === paidThisMonthYear){
                julyData = theIncome + julyData;
              }

              //get august data
              const augData = 7 + " " + thisYear;
              if(augData === paidThisMonthYear){
                augustData = theIncome + augustData;
              }

              //get september Data
              const sepData = 8 + " " + thisYear;
              if(sepData === paidThisMonthYear){
                septemberData = theIncome + septemberData;
              }

              //get oct Data
              const ocDataq = 9 + " " + thisYear;
              if(ocDataq === paidThisMonthYear){
                octData = theIncome + octData;
              }

              //get nov Data
              const novDataq = 10 + " " + thisYear;
              if(novDataq === paidThisMonthYear){
                novData = theIncome + novData;
              }

              //get dec Data
              const decDataq = 11 + " " + thisYear;
              if(decDataq === paidThisMonthYear){
                decData = theIncome + decData;
              }

            })

            setAmountPaid(income);
            setAmountPaidThisMonth(thisMonthsIncome);

            setJanGraData(janData);
            setFebGraData(febData);
            setMarchGraData(marchData);
            setAprilGraData(aprilData);
            setMayGraData(mayData);
            setJuneGraData(juneData);
            setJulyGraData(julyData);
            setAugGraData(augustData);
            setSepGraData(septemberData);
            setOctGraData(octData);
            setNovGraData(novData);
            setDecGraData(decData);


            //getting this months balance
            if(amountPaidThisMonth >= courseFeeInstallments){
              setBalanceThisMonth(0)
             }else{
              const mainBalance = courseFeeInstallments - amountPaidThisMonth;
              setBalanceThisMonth(mainBalance)
             }

            //this month
            
            

        })
        //end



        } else {
        // User is signed out
        }
    });

    //view course price
   function  viewCoursePrice(course){

        const courseQ = query(collection(db, "courses"), where("mcourseName", "==", course));

        
        getDocs(courseQ).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((doc)=>{
            const mcoursePrice = doc.data().mcoursePrice;
            const feeInstallments = doc.data().feeInstallments;
            
            
            setCoursePrice(mcoursePrice);
            setCourseFeeInstallments(feeInstallments);
            })
        })

    }
    //end

    //fetch all transactions
    useEffect(() => {

      //check if the user is a student
      onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
          //this is the magic
          const fetchData = async () => {
            const q = query(collection(db, "Income"), where("studentId", "==", uid));
            let studentsItem = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              studentsItem.push({ id: doc.id, ...doc.data() });
              setStudentList([...studentsItem]);   
            });
          };

        fetchData();
        //end magic


        } else {
          // User is signed out
          }
      });
      //end
    

    }, []);
    //end fetching transactions


    //find this months balance
    function thisMonthBalance(){

      

      

    }

    //thisMonthBalance();

      


  return (
    <div>
         <div className='feeComp'>
            
            <div className='feeTopDiv'>
                <h6>Fee Reports</h6>

                <div className='feeItemsCont'>
                  <div className='feeItem'>
                    <div className='feeItemTop'>
                      <FontAwesomeIcon icon={faCloud} className=''/>
                      <p>{coursePrice}</p>
                    </div>
                    <p className='feeItemP'>Current course fee</p>
                  </div>

                  <div className='feeItem'>
                    <div className='feeItemTop'>
                      <FontAwesomeIcon icon={faCloud} className=''/>
                      <p>{amountPaid}</p>
                    </div>
                    <p className='feeItemP'>Amount Paid</p>
                  </div>

                  <div className='feeItem'>
                    <div className='feeItemTop'>
                      <FontAwesomeIcon icon={faCloud} className=''/>
                      <p>{courseFeeInstallments}</p>
                    </div>
                    <p className='feeItemP'>Payment Term (per month)</p>
                  </div>

                  <div className='feeItem'>
                    <div className='feeItemTop'>
                      <FontAwesomeIcon icon={faCloud} className=''/>
                      <p>{amountPaidThisMonth}</p>
                    </div>
                    <p className='feeItemP'>Fee paid this Month</p>
                  </div>

                  <div className='feeItem'>
                    <div className='feeItemTop'>
                      <FontAwesomeIcon icon={faCloud} className=''/>
                      <p>{balanceThisMonth}</p>
                    </div>
                    <p className='feeItemP'>Balance this month</p>
                  </div>

                </div>              



            </div>  


            <div className='feelineGraph'>
                <Line className='theFeeLineGraph'
                      datasetIdKey='id'
                      data={{
                        labels: ['Jan', 'Feb', 'March','Apr', 'May', 'June','July', 'Aug', 'Sept','Oct', 'Nov', 'Dec'],
                        datasets: [
                          {
                            id: 1,
                            label: 'Fee payment',
                            data: [janGraData,febGraData, marchGraData, aprilGraData, mayGraData, juneGraData,julyGraData,augGraData, sepGraData,octGraData,novGraData,decGraData],
                            backgroundColor: [
                              'rgba(255, 99, 132, 0.2)',
                              'rgba(54, 162, 235, 0.2)',
                              'rgba(255, 206, 86, 0.2)',
                              'rgba(75, 192, 192, 0.2)',
                              'rgba(153, 102, 255, 0.2)',
                              'rgba(255, 159, 64, 0.2)'
                          ],
                            borderColor: [
                              'rgba(255, 99, 132, 1)',
                              'rgba(54, 162, 235, 1)',
                              'rgba(255, 206, 86, 1)',
                              'rgba(75, 192, 192, 1)',
                              'rgba(153, 102, 255, 1)',
                              'rgba(255, 159, 64, 1)'
                          ],
                          borderWidth: 1
                          }
                        ],
                        
                      }}
                    />
              </div> 

              <h6 className='feeTransactionHeading'>All Transactions</h6>  

              <div className='feeTableCont'>
                <Table striped className='feeTable'>
                  <thead>
                      <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                      <th>Action</th>
                      </tr>
                  </thead>
                  <tbody>

                  {studentsList.map((mystudent) => (
                    <tr  key={Math.random()}>
                       <td>{mystudent.paymentDate.toDate().toDateString()}</td>
                        <td>{mystudent.incomeAmount}</td>
                        <td>{mystudent.paymentMethod}</td>
                        <td> <a href={'https://ubunifu.web.app/view-receipt?' + mystudent.invoiceNumber} target="_blank" className='btn btn-warning feeButton' rel="noreferrer">View Receipt</a> </td>
                    </tr>
                  ))}

   
                    
                  </tbody>
                </Table>
              </div>
     
        </div>
    </div>
  )
}

export default FeeComp