import React from 'react'
import taskOne from '../Images/tasksIcon.svg'
import mercyInstr from '../Images/back3.jpg'
import instPl from '../Images/sampleprofile.jpg'

function AssTasksComp() {
  return (
    <div className='assTasksComp'>
        <h5>Assigned Tasks</h5>

        <div className='assContainer'>
            <div className='assTop'>

                <div className='taskCont1'>
                    <img src={taskOne} alt=""/>
                    <div>
                        <h3>0</h3>
                        <p>Assigned Tasks</p>
                    </div>
                </div>
                <div className='taskCont2'>
                    <img src={taskOne} alt=""/>
                    <div>
                        <h3>0</h3>
                        <p>Submitted Tasks</p>
                    </div>
                </div>
                <div className='taskCont3'>
                    <img src={taskOne} alt=""/>
                    <div>
                        <h3>0</h3>
                        <p>Reviewed Tasks</p>
                    </div>
                </div>

            </div>

            <div className='theAssMainCont'>
                <div className='assInstructorCont'>
                    <div>
                        <img src={mercyInstr} alt="" />
                        <div className='assInstrOpacity'></div>
                        <div className='assInstrProf'>
                            <div className='assInstrProfImg'>
                                <img src={instPl} alt="" />
                            </div>
                            <h6 >Reinhard Omukuba</h6>
                            <a href='https://github.com/reinhard-omukuba'>Github Account</a>
                        </div>
                    </div>

                    <div className='assAboutIntr'>
                        <h6>About your Instructor</h6>
                        <p>I’m a software engineer working on Probabilistic Reasoning, Statistics and Deep generative models (AI). I got into the world of professional software development in (2013), coding in PhP. But then I learned some other languages like Java, Visual Basic 6, Python, Javascript, C, C++, R, Caml Light and Prolog. But Java & JavaScript has always been my favorite. </p>
                    </div>


                </div>

                <div className='taskItemsCont'>
                    <h6>My Assignments</h6>

                </div>

            </div>
        </div>
    </div>
  )
}

export default AssTasksComp