import React , { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import logo from '../Images/logo-main-black.png'

import profilePictureContImage from '../Images/defprofile.png'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

//mobile navbar
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

//icons
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faCoffee} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { faUsd} from '@fortawesome/free-solid-svg-icons'
import { faMagic} from '@fortawesome/free-solid-svg-icons'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'

function DashnavIn() {

    const [userProfilePicture, setProfilePicture] = useState(profilePictureContImage);

    //instantiate auth
    const auth = getAuth();
  
    onAuthStateChanged(auth, (user) => {
      if (user) {
      //if user is logged in 
      const uid = user.uid;

        // get employee prof pic
        const m = query(collection(db, "Employees"), where("docId", "==", uid));
        getDocs(m).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((doc)=>{
              const profilePic = doc.data().profilePic;

              setProfilePicture(profilePic);
              let myxx = profilePic + "1";          
              if(myxx === "undefined1"){
                setProfilePicture(profilePictureContImage);         
              }else{
                
              }

            })
        })

  
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((doc)=>{
  
            let userType = doc.data().userType;


            if (userType !== "Instructor"){
                console.log("You are not an instructor chief!")
                navigate("/dashboard")
            }
  
           
            })
        })




  
      } else {
        // User is signed out
        }
    });
  
    let navigate = useNavigate();
    function goToProfile(){
      navigate("/in/profile")
    }

  return (
    <div className='dashNavTwo'> 
        <div className='dashMenuIcon'>
          {/* <FontAwesomeIcon icon={faBars} className="dashNavIco "/> */}

          {[false, ].map((expand) => (
            <Navbar key={expand} bg="" className="" expand={expand} >
              <Container fluid>
              
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img src={logo} alt="" className='dashnavLogo' />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/dashboard" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faHome} className="laftNavIcon"/>
                          Dashboard
                        </Link>
                      </div>
                      <p className='mobileMenuTitles'>ACADEMIC</p>
                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/academic" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faVideoCamera} className="laftNavIcon"/>
                          Academic
                        </Link>
                      </div>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/teams" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faUsers} className="laftNavIcon"/>
                          Teams
                        </Link>
                      </div>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/tasks" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faBookmark} className="laftNavIcon"/>
                          Assigned Tasks      
                        </Link>
                        <span className='leftLinkNotif'>0</span>
                      </div>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/startups" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faBriefcase} className="laftNavIcon"/>
                          My Startups
                        </Link>

                      </div>

                      <p className='mobileMenuTitles'>RESEARCH & DEV</p>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/assigned-profects" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faCoffee} className="laftNavIcon"/>
                          Client's Projects
                        </Link>
                      </div>

                      <p className='mobileMenuTitles'>UPDATES & HELP</p>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/class-reports" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faCalendarDay} className="laftNavIcon "/>
                          Class Report
                        </Link>
                      </div>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/fee-report" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faUsd} className="laftNavIcon "/>
                          Fee Report
                        </Link>
                      </div>

                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/ai-assistant" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faMagic} className="laftNavIcon "/>
                          AI Assistant
                        </Link>
                      </div>
                      <p className='mobileMenuTitles'>ACCOUNT</p>
                      <div className='leftLinkCont d-flex align-items-center'>
                        <Link to="/profile" className='leftNavLinks '>
                          <FontAwesomeIcon icon={faUser} className="laftNavIcon "/>
                          Profile
                        </Link>
                      </div>
                    </Nav>

                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>

        <div className='searchDashCont'>

        </div>
        

        <div className='allNotifCont'>

          <div className='chatCont'>
            <FontAwesomeIcon icon={faEnvelope} className="dashNoticIcon "/>
            <p className='notifCounter2'>0</p>
          </div>
          <div className='NotifCont'>
            <FontAwesomeIcon icon={faBell} className="dashNoticIcon "/>
            <p className='notifCounter'>0</p>
          </div>
          <div className='profCont' onClick={goToProfile}>
            <img src={userProfilePicture} alt="" />
          </div>

        </div>        
  </div>
  )
}

export default DashnavIn