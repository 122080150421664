import React from 'react'
import LeftmenuIn from '../Components/LeftmenuIn'
import DashnavIn from '../Components/DashnavIn'
import ProfileComp from '../Instructor/ProfileComp'

function Profile() {
  return (
    <div className='mainContainer'>
        <LeftmenuIn/>
        <div className='theMainContainer'>
        <DashnavIn/>
        <ProfileComp/>
        
        </div>
    </div> 
  )
}

export default Profile