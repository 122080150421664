// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAM2P7cVGotXsUkx76iN6R9ySAWzSKn1ME",
    authDomain: "ubunifu-college.firebaseapp.com",
    databaseURL: "https://ubunifu-college.firebaseio.com",
    projectId: "ubunifu-college",
    storageBucket: "ubunifu-college.appspot.com",
    messagingSenderId: "772246694093",
    appId: "1:772246694093:web:4e12c420cad7fe29e72184",
    measurementId: "G-2PVBC1D0QP"
 };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export {app};
export {analytics}
export {db}