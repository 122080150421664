import React, { useRef, useState }  from 'react'
import logo from '../Images/logo-main-black.png'
import {NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faCoffee} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { faUsd} from '@fortawesome/free-solid-svg-icons'
import { faMagic} from '@fortawesome/free-solid-svg-icons'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faQuoteLeft} from '@fortawesome/free-solid-svg-icons'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

//routers
import {useNavigate } from "react-router-dom";

function LeftmenuIn() {

    let navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {

        if (user) {

        } else {
        // User is signed out
        navigate("/login")
        }
    })

  return (
   <div className='leftNav'>
   
        <img src={logo} alt="" className='dashnavLogo' />
      
      
        <br />
        <p>HOME</p>
        

        <NavLink to="/in/dashboard" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faHome} className="laftNavIcon activelaftNavIcon"/>
            Dashboard
        </NavLink>

        <p>ACADEMIC</p>

        <NavLink to="/in/my-classes" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faVideoCamera} className="laftNavIcon"/>
            My Classes
        </NavLink>


        <NavLink to="/in/students" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUsers} className="laftNavIcon"/>
            Students
        </NavLink>



        <NavLink to="/in/channels" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBookmark} className="laftNavIcon"/>
            Channels  
            <span className='leftLinkNotif'>0</span>   
        </NavLink>
         


        <NavLink to="/in/workshops" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBriefcase} className="laftNavIcon"/>
            Workshops
        </NavLink>

        {/* className={`leftLinkCont d-flex align-items-center ${activeMenu3}`} */}

        <p>RESEARCH & DEV</p>

        <NavLink to="/in/assigned-profects" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCoffee} className="laftNavIcon"/>
            Client's Projects
        </NavLink>


        <p>UPDATES & HELP</p>


        <NavLink to="/in/students-reports" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCalendarDay} className="laftNavIcon "/>
            Students Report
        </NavLink>


        <NavLink to="/in/my-thoughts" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faQuoteLeft} className="laftNavIcon "/>
            My Thoughts
        </NavLink>

        <NavLink to="/in/ai-assistant" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
          <FontAwesomeIcon icon={faMagic} className="laftNavIcon "/>
          AI Assistant
        </NavLink>
        
        <p>ACCOUNT</p>
        <NavLink to="/in/profile" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUser} className="laftNavIcon "/>
            Profile
        </NavLink>


      

    </div>
  )
}

export default LeftmenuIn