import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import FeeComp from './FeeComp'

function FeeReports() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <FeeComp/>
            </div>
        </div>  
    </div>
  )
}

export default FeeReports