import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import ClientsComp from './ClientsComp'

function ClientProjects() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <ClientsComp/>
            </div>
        </div>
    </div>
  )
}

export default ClientProjects