import React from 'react'
import LeftmenuIn from '../Components/LeftmenuIn'
import DashnavIn from '../Components/DashnavIn'
import DashInComp from './DashInComp'

function DashboardIn() {
  return (
    <div className='mainContainer'>
    <LeftmenuIn/>
    <div className='theMainContainer'>
      <DashnavIn/>
      <DashInComp/>
     
    </div>
</div>  
  )
}

export default DashboardIn