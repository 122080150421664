import React from 'react'

function ChannelsComp() {
  return (
    <div className='instructorComponent'>
        ChannelsComp
    </div>
  )
}

export default ChannelsComp