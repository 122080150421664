import React , { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";



//routers
import {useNavigate } from "react-router-dom";




function MyClassesComp() {

  const auth = getAuth();
  let navigate = useNavigate();
  const [studentsList, setStudentList] = useState([]);



  
  //fetch all Active Students
  useEffect(() => {

    //check if the user is a student
     onAuthStateChanged(auth, (user) => {
       if (user) {
       //if user is logged in 
         const uid = user.uid;
             
        //this is the magic
        const fetchData = async () => {
          const q = query(collection(db, "classSessions"), where("instructor", "==", uid));
          let studentsItem = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            studentsItem.push({ id: doc.id, ...doc.data() });
            setStudentList([...studentsItem]);   
          });
        };

        fetchData();
        //end magic
             
     
       } else {
         // User is signed out
         }
     });
     //end
   

 }, []);
 //end fetching active students



 function startChat(roomid){
    console.log(roomid)

    navigate('/in/class-session', { state: { myData: roomid } });

 }


  return (
    <div className='teamsComponent'>
        <div className='teamsTopBar'>
            <h5 className='tHide1'>My Classes</h5>
            <h5 className='tHide2'>My Classes</h5>
        </div>

        <div className='teamsContainer'>

          {studentsList.map((mystudent) => (

            <div className='teamsItem2' key={Math.random()}>

                <div className='teamsItemProfilecont2'>

                  <h6>{mystudent.classDesc}</h6>
                  <hr />
                  <p>Intake Date: {mystudent.intakeDate}</p>
                  <p>Classes Started on: {mystudent.startDate}</p>  
                  <p>Classes Start Time: {mystudent.startTime}</p> 
                  <p>Classes End Time: {mystudent.endTime}</p> 

                </div>
                <div className='teamsItemBottomCont2'>
                  <div className='teamsItemChatbtn2' onClick={() => startChat(mystudent.roomID)}>
                    <p>Join Class</p>
                  </div>

                </div>
            </div>
            ))}


        <div className='clearFixAll'></div>
           



        </div>


        <br /> <br />

    </div>
  )
}

export default MyClassesComp