import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd} from '@fortawesome/free-solid-svg-icons'

//routers
import {useNavigate } from "react-router-dom";

function StartupComp() {

  let navigate = useNavigate();
  function goToStartupPage(){
    navigate("/add-startup")

  }

  return (
    <div className='startupComp'>
        <div className='startupTop'>
            <h5>My Startups</h5>
            <button onClick={goToStartupPage}> <FontAwesomeIcon icon={faAdd} className="liveSessionIcon"/> Add a Startup</button>
        </div>

        <div className='startupContainer'>

        </div>
         
    </div>
  )
}

export default StartupComp