import React, { useState } from 'react'
import './dash.css'
import './ResponsiveDash.css'
import dashOne from '../Images/dashone.svg'
import dashTwo from '../Images/ayurveda.jpg'
import instPl from '../Images/sampleprofile.jpg'
import feeImage from '../Images/custom-13.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt} from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table';
// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";


function DashComp() {

    //states
    const [loggedInUser, setLoggedInUser] = useState("");
    const [feeBalance, setFeeDeposit] = useState("");
    const [balanceThisMonth, setBalanceThisMonth] = useState("");
    const [courseFeeInstallments, setCourseFeeInstallments] = useState("");
    const [amountPaidThisMonth, setAmountPaidThisMonth] = useState("");

    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((doc)=>{
            const username = doc.data().bio;
            const course = doc.data().course;

            viewCoursePrice(course);

            setLoggedInUser(username);
            })
        })
        } else {
        // User is signed out
  
        }


        //calculate amount paid
        const courseQ = query(collection(db, "Income"), where("studentId", "==", user.uid));  
        getDocs(courseQ).then((QuerySnapshot)=>{
            let income = 0;
            let thisMonthsIncome = 0;


            QuerySnapshot.forEach((doc)=>{
                const incomeAmount = doc.data().incomeAmount;
                const paymentDate = doc.data().paymentDate;
                const theIncome = parseInt(incomeAmount);

                //calculating total income
                income = theIncome + income;

                //get the current month
                const todaysDate = new Date();
                const thisYear = todaysDate.getFullYear();
                const thisMonth = todaysDate.getMonth();
                const thisMonthYear = thisMonth + " " + thisYear;

                //get when amount was paid
                const datePaid = paymentDate.toDate().toUTCString();
                const date = new Date(datePaid);
                const monthPaid = date.getMonth();
                const yearPaid = date.getFullYear();              
                const paidThisMonthYear = monthPaid + " " + yearPaid;

                //compare prices will be paid this year
                if(thisMonthYear === paidThisMonthYear){
                    thisMonthsIncome = theIncome + thisMonthsIncome;
                }


            })

            setAmountPaidThisMonth(thisMonthsIncome);



            //getting this months balance
            if(amountPaidThisMonth >= courseFeeInstallments){
                setBalanceThisMonth(0)
                }else{
                const mainBalance = courseFeeInstallments - amountPaidThisMonth;
                setBalanceThisMonth(mainBalance)
                }

            //this month
            
            

        })
        //end
    });

   function  viewCoursePrice(course){
 

        const courseQ = query(collection(db, "courses"), where("mcourseName", "==", course));
        
        getDocs(courseQ).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((doc)=>{
            const feeDeposit = doc.data().feeDeposit;
            const feeInstallments = doc.data().feeInstallments;
            setCourseFeeInstallments(feeInstallments);
            
            setFeeDeposit(feeDeposit);
            })
        })


    }

    //date functions
    const todaysDate = new Date();
    const todayDay = todaysDate.getDate();
    const todayDayOfWeek = todaysDate.getDay();
    const todayMonth = todaysDate.getMonth();
    const todayYear = todaysDate.getFullYear();

    const weekArray = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthArray = ["January","February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [todayDayState] = useState(todayDay);
    const [todayWeekState] = useState(weekArray[todayDayOfWeek]);
    const [todayMonthState] = useState(monthArray[todayMonth]);
    const [todayYearState] = useState(todayYear);
    //dates end
    


  return (
    <div className='dashComp'>

        <div className='dashFlex1'>
            <div className='dash1'>
                <div className='dash1Cont'>
                    <h4>Welcome</h4>
                    <p>{loggedInUser}</p>
                </div>
                <img src={dashOne} alt="" />
            </div>

            <div className='dash2'>

                <div className='dChild1'>
                    <div className='css-1r9i9ek'>
                        <div className='css-622j8h'>
                            <FontAwesomeIcon icon={faGraduationCap} className="dashMIcons "/>
                        </div>
                    </div>

                    <div className='css-1cdh7ge'>
                        <h5>1</h5>
                        <p>Course</p>
                    </div>

                </div>
                <div className='dChild2 d-flex'>
                    <div className='the2child2'>
                        <p>Fee Due</p>
                        <h6>KES. {balanceThisMonth}</h6> 
                    </div>
                    <div>
                        <img src={feeImage} alt="" />
                    </div>                
                </div>
                <div className='dChild3'> 
                    <div className='d-flex align-items-center '>
                        <FontAwesomeIcon icon={faCalendarAlt} className=" "/>
                        <h5> 5th</h5>
                    </div>                                    
                    <p>Fee Due date</p>
                </div>

                <div className='dChild4'>
                    <div className='css-xjuj3x'>
                        <div className='css-o2tasi'>
                            <div className='instructorPl'>
                                <img src={instPl} alt="" />
                            </div>
                            
                        </div>
                    </div>

                    <div className='instructrDtls'>
                        <h6>Instructor</h6>
                        <p>Reinhard</p>
                    </div>
                </div>

            </div>
        </div>

        <div className='dashFlex1'>
            <div className='dash3'>
                <hr />
                <h6>Become a Code Chef</h6>
                <p>Your journey to becoming a code chef starts here</p>
                <img src={dashTwo} alt="" />
            </div>

            <div className='dash4'>
                <div className='dash4Top'>
                    <h6>Your Timetable</h6>
                    <p>Week 1</p>
                </div>

                <Table striped className='timeTable'>
                <thead>
                    <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Lesson</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>15th Aug</td>
                    <td>8 - 10 am</td>
                    <td>Firebase Auth</td>
                    </tr>
                    <tr>
                    <td>16th Aug</td>
                    <td>8 - 10 am</td>
                    <td>Auth State</td>
                    </tr>
                    <tr>
                    <td>17th Aug</td>
                    <td>8 - 10 am</td>
                    <td>Manage Users</td>
                    </tr>
                    <tr>
                    <td>18th Aug</td>
                    <td>8 - 10 am</td>
                    <td>Firestore</td>
                    </tr>
                    <tr>
                    <td>19th Aug</td>
                    <td>8 - 10 am</td>
                    <td>Firestore</td>
                    </tr>
                </tbody>
                </Table>

                

            </div>

            <div className='dash5'>

                <div className='dashDateCont'>
                    <h2>{todayDayState}</h2>
                </div>
                <h6>{todayWeekState}</h6>
                <p>{todayMonthState} {todayYearState}</p>

            </div>

        </div>



    </div>
  )
}

export default DashComp