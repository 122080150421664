import React from 'react'
import DailyIframe from '@daily-co/daily-js';
// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";


//routers
import {useNavigate } from "react-router-dom";

function GroupCall() {

    const auth = getAuth();
     let navigate = useNavigate();

    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;
      
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{
            const course = doc.data().course;
            viewCoursePrice(course);
            })
        })
        } else {
        // User is signed out
        navigate("/login")
        }
    });
    
     //view the course
   function  viewCoursePrice(course){

    //show course details
    const courseQ = query(collection(db, "courses"), where("mcourseName", "==", course));    
    getDocs(courseQ).then((QuerySnapshot)=>{

        QuerySnapshot.forEach((doc)=>{ 
        const groupLink = doc.data().groupLink;      
        
        let callFrame = DailyIframe.createFrame({
            showLeaveButton: true,
            iframeStyle: {
            position: 'fixed',
            top: '0',
            right: '0',
            width: '100%',
            height: '100%',
            },
        });
        callFrame.join({ url: groupLink });


        })

    })
    }


  return (
    <div className='onSessionCont'>

        <p>Loading....</p>

    </div>
  )
}

export default GroupCall