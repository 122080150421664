import React from 'react'
import logo from '../Images/logo-main-black.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons'

function Complete() {
  return (
    <div className='completePage'>
        <div className='card myCard'>
            <img src={logo} className="completeLogo" alt="" />
            <h5>Application Successful</h5>
            <FontAwesomeIcon icon={faCheckCircle} className="completeCheck"/>
            <p>Application Successful. Check your email for the confirmation message and fee structure, if you cant see the email look under <u>promotions or updates tab</u>. Our admissions team will contact you for a suitability interview to determine if you are the kind of student we would want at our school.</p>
            <Link to="/login" className='completeLink'>Login to your student's Account</Link>
        </div>

    </div>
  )
}

export default Complete