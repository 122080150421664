import React from 'react'
import LeftmenuIn from '../Components/LeftmenuIn'
import DashnavIn from '../Components/DashnavIn'
import ChannelsComp from './ChannelsComp'

function AiAssistant() {
  return (
    <div className='mainContainer'>
        <LeftmenuIn/>
        <div className='theMainContainer'>
        <DashnavIn/>
        <ChannelsComp/>
        
        </div>
    </div> 
  )
}

export default AiAssistant