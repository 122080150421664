import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import icoOne from '../Images/ico1.svg'
import icoTwo from '../Images/ico2.svg'
import icoThree from '../Images/ico3.svg'
import icoFour from '../Images/ico4.svg'
import icoFive from '../Images/ico5.svg'
import icoSix from '../Images/ico6.svg'
import icoSeven from '../Images/ico7.svg'
import icoEight from '../Images/ico8.png'
import icoNine from '../Images/ico9.png'
import icoTen from '../Images/ico10.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay} from '@fortawesome/free-solid-svg-icons'
import { faArrowDown} from '@fortawesome/free-solid-svg-icons'
import liveClass from '../Images/live.svg'
import theClasses from '../Images/classes.svg'
import theStudents from '../Images/students.svg'
import theWorld from '../Images/world.svg'
import theMaggie from '../Images/backmain.jpg'
import theMain2 from '../Images/main2.jpg'
import {useNavigate } from "react-router-dom";


function Home() {
  //instantiating use navigate
  let navigate = useNavigate();
  //naigating to ogin page
  function goToLogin(){
      navigate("/login");
  }


  return (
    <div>
      
      <section className='Hero'>
          <video src='https://res.cloudinary.com/ubunifu/video/upload/v1607186910/pexels-rodnae-productions-5923291_jd1rwl.mp4' className='theVideo' autoPlay muted loop></video>
          <div className='opacity'></div>
          <NavBar/>
          <div className='xContent'>
            <h1>WE ARE AROUND THE GLOBE!</h1>
            <p>Whatever your focus, your vision, or your passion, this is the place to realize your potential. Find your purpose!</p>
            <div className='d-flex'>
              <span className='countryCont'><img src={icoSix} alt="" /></span>
              <span className='countryCont'><img src={icoEight} alt="" /></span>
              <span className='countryCont'><img src={icoOne} alt="" /></span>

              <span className='countryCont'><img src={icoThree} alt="" /></span>
              <span className='countryCont'><img src={icoFour} alt="" /></span>
              <span className='countryCont'><img src={icoFive} alt="" /></span>
                     
              <span className='countryCont'><img src={icoNine} alt="" /></span>
              <span className='countryCont'><img src={icoTen} alt="" /></span>
              <span className='countryCont'><img src={icoSeven} alt="" /></span>
            </div>
            <button className='signInbtn' onClick={goToLogin}>Sign In <FontAwesomeIcon icon={faCirclePlay} className="theGrad"/></button>

          </div>
          <div className='scrollDown'>
                <FontAwesomeIcon icon={faArrowDown} className="theScroll"/>
          </div>
      </section>

      <section className='aboutSec'>

        <div className='abSec1'>
          <h6>CENTRE FOR AI RESEARCH & DEVELOPMENT</h6>
          <h3>Live Interactive Virtual classes.</h3>
          <p>Get a classroom experience from anywhere you are, interact with fellow students, build and launch amazing products!</p>
          <a href='https://ubunifucollege.com/apply' className='signUpbtn'>Sign Up</a>
        </div>

        <div className='abSec2'>
          <img src={theMain2} alt="" className='boy4'/>
          <div className='lineCont'>           
            <div className='theLine'/> <p> Project Based Learning</p>
          </div>        
        </div>

        <div className='abSec3'>
          <img src={theMaggie} alt="" className='boy4'/>
          <div className='lineCont'>
            <div className='theLine'/> <p>Learn from accomplished Devs</p>
          </div>      
        </div>

      </section>

      <section className='section3'>
        <div className='opacity2'></div>
        <div className='section3Cont'>
          <h2>Are your dreams big enough?</h2>
          <a href='https://ubunifucollege.com/apply'>GET STARTED TODAY</a>  
        </div>

      </section>


      <section className='section4'>

        <div className='sec4a'>
          <h5>Our difference</h5>
          <h1>Startup School; Build & Launch your startup</h1>
          <p>Ubunifu's business model is built around helping the student get top-notch skills with the purpose of building a startup, a Software as a Service product, or custom business Software such as ERPs, E-Commerce, Point of Sale solutions, Management solutions etc and launch to market before they graduate.</p>
          <p>This is the place for new ventures to become the new unicorn, new ideas transformed into reality and you get to meet amazing people who share the same dream as you.</p>
          <a href='https://ubunifucollege.com/apply'>Sign Up</a>

        </div>

        <div className='sec4b'>

          <div className='sec4bDivs'>
            <img src={liveClass} alt="" />
            <h6>Class Sessions</h6>
            <p>Our class Sessions are two hours a day, Monday - Friday</p>
          </div>

          <div className='sec4bDivs'>
            <img src={theClasses} alt="" />
            <h6>Class Setup</h6>
            <p>Our virtual class Setup consists of a maximum of 10 students</p>
          </div>

          <div className='sec4bDivs'>
            <img src={theStudents} alt="" />
            <h6>Courses Offered</h6>
            <p>Software development, Data Science, Machine Learning & Blockchain</p>
          </div>

          <div className='sec4bDivs'>
            <img src={theWorld} alt="" />
            <h6>Countries</h6>
            <p>We have students from over 6 Countries working on cool projects</p>
          </div>

        </div>

      </section>



      <section className='section5'>

        <div className='sec5div1'></div>
        <div className='sec5div2'>
          <h5>We took our classroom experience and put it on the internet!</h5>
          <p>In our traditional classroom, we have a whiteboard where the tutor will be writing and explaining every piece of code before its executed. We also have a projector where you will be able to see the tutor write and demonstrate their code before you start coding. When you have a question or a problem you can ask the tutor and he/she would come and assist you.</p>
          <a href='https://ubunifucollege.com/apply'>Sign Up</a>

        </div>
        <div className='sec5div3'></div>
        <div className='sec5div4'>
          <h5>So how does it work?</h5>
          <p>We developed a virtual learning platform that simulates how we work. We have a virtual whiteboard where the tutor will be writing and explaining every piece of code before its executed. 
            We have the share screen where you will be able to see the tutor write and demonstrate their code before you start coding. As you are coding your screens will be shared with the tutor on their extra monitors where they can see every student writing code. 
            When you have a problem or a question, the tutor would look at your screen from his monitor and be able to help</p>

        </div>

      </section>


      <section className='section6'>

        <div className='sec6div1'>

          <div className='sec6div1A'></div>

        </div>
        <div className='sec6div2'>

          <h5>Project based Learning</h5>
          <h1>Learn by Doing</h1>
          <p>Project-based learning is a proven, alternative learning methodology to the traditional teacher-led lecture and memorization educational method. Rather than teaching a lot of theory and having students occasionally apply a fraction of their knowledge on a class project, we do the opposite. We give our students increasingly difficult programming challenges to solve with minimal initial directions on how to solve them. As a result, our students learn to look for the theory and tools they need, how to understand them, how to apply them, and how to work as a team.</p>
          <a href='https://ubunifucollege.com/about'>About Us</a>

        </div>
      </section>

      <Footer/>

    </div>
  )
}

export default Home