import './App.css';
import './Respdesign.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
//import Pages
import Home from './Pages/Home';
import Login from './Pages/Login';
import Recoverpass from './Pages/Recoverpass';
import Dashboard from './Dashboard/Dashboard';
import Academic from './Dashboard/Academic';
import TheTeams from './Dashboard/TheTeams';
import AssTasks from './Dashboard/AssTasks';
import MyStartups from './Dashboard/MyStartups';
import ClientProjects from './Dashboard/ClientProjects';
import FeeReports from './Dashboard/FeeReports';
import MyProfile from './Dashboard/MyProfile';
import AiAssistant from './Dashboard/AiAssistant';
import DarasaReports from './Dashboard/DarasaReports';
import EditAccount from './Dashboard/EditAccount';
import Complete from './Pages/Complete';
import ClassSession from './Dashboard/ClassSession';
import GroupCall from './Dashboard/GroupCall';
import AddStartup from './Dashboard/AddStartup';
import MyRooms from './Dashboard/MyRooms';
import ClassCalls from './Dashboard/ClassCalls';
import Chat from './Dashboard/Chat';

// Instructor Account
import DashboardIn from './Instructor/DashboardIn';
import MyClasses from './Instructor/MyClasses';
import Students from './Instructor/Students';
import MyChannels from './Instructor/MyChannels';
import Workshops from './Instructor/Workshops';
import ClientProjo from './Instructor/Clientprojects';
import Studentsreposts from './Instructor/Studentsreposts';
import MyThoughts from './Instructor/MyThoughts';
import AiAssistantIn from './Instructor/AiAssistant';
import ProfileIn from './Instructor/Profile';
import Updateaccount from './Instructor/Updateaccount';
import AllCalls from './Instructor/AllCalls';

function App() {
  return (
    <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="recover" element={<Recoverpass />} />

            {/* STUDENTS ACCOUNT */}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="academic" element={<Academic />} />
            <Route path="teams" element={<TheTeams />} />
            <Route path="tasks" element={<AssTasks />} />
            <Route path="startups" element={<MyStartups />} />
            <Route path="assigned-profects" element={<ClientProjects />} />
            <Route path="fee-report" element={<FeeReports />} />
            <Route path="class-reports" element={<DarasaReports />} />
            <Route path="ai-assistant" element={<AiAssistant />} />
            <Route path="profile" element={<MyProfile />} />            
            <Route path="edit-profile" element={<EditAccount />} />
            <Route path="complete" element={<Complete />} />
            <Route path="onsession" element={<ClassSession />} />
            <Route path="group-call" element={<GroupCall />} />
            <Route path="add-startup" element={<AddStartup />} />
            <Route path="my-rooms" element={<MyRooms />} />
            <Route path="class-session" element={<ClassCalls />} />
            <Route path="chat" element={<Chat />} />

            

            {/* INSTRUCTOR ACCOUNT*/}
            <Route path="in/dashboard" element={<DashboardIn />} />
            <Route path="in/my-classes" element={<MyClasses />} />
            <Route path="in/students" element={<Students />} />
            <Route path="in/channels" element={<MyChannels />} />
            <Route path="in/workshops" element={<Workshops />} />
            <Route path="in/assigned-profects" element={<ClientProjo />} />
            <Route path="in/students-reports" element={<Studentsreposts />} />
            <Route path="in/my-thoughts" element={<MyThoughts />} />
            <Route path="in/ai-assistant" element={<AiAssistantIn />} />
            <Route path="in/profile" element={<ProfileIn />} />
            <Route path="in/edit-profile" element={<Updateaccount />} />
            <Route path="in/class-session" element={<AllCalls />} />
            


          </Routes>       
        </BrowserRouter>     
    </div>
  );
}

export default App;